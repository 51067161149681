import { useEffect, useState } from 'react';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import CrisisWidgetV2 from '../../components/general/crisisWidgetV2';

const TestWidgetPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const products = [
        'EMR-LEB-24-000',
        'EMR-DIS-PSE-2023-01',
        'EMR-DIS-PSE-2023-01-01',
        'MKD-WEL-SDQ-2023-01',
        'MKD-SDQ-OAW-2023-02-119',
        'EM-SDQ-EQ1-02-2023',
        'MKD-EMR-WNT-2023-01',
    ]
    /* -------------------------------------------------------------------------- */
    /* -------------------------------------------------------------------------- */
    return (
        <NewHelmetLayout hideFooter>
            <div className="min-h-[300px] w-full">
                <div className="w-full mx-auto my-4">
                    {/* <HtmlContent htmlCode={rawHtml} /> */}
                    <CrisisWidgetV2 productsList={products}
                        // defaultName="Sadaqah Jariyah"
                        defaultCreator="MKD-GEN-MSD-2023-01-121" />
                </div>
            </div>
        </NewHelmetLayout >
    );
}

export default TestWidgetPage;
