import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import HomePageTabContent from './tab-content';

const HomePageTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { urgentAppeals, topAppealsProducts, palestineEmergencyProducts, zakatProducts, waterWellsProducts, bgColor } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */
  const tabButtonsProducts = [
    {
      title: 'URGENT APPEALS',
      description: '',
      products: urgentAppeals,
    },
    {
      title: 'PALESTINE EMERGENCY',
      description: '',
      products: palestineEmergencyProducts,
    },
    {
      title: 'top appeals',
      products: topAppealsProducts,
      description: 'Last Ramadan , you helped deliver an impact of 7,147,494. Help us reach more now.',
    },
    {
      title: 'zakat',
      products: zakatProducts,
      description: 'Fulfil your Islamic obligation. Calculate and give your Zakat today.',
    },
    {
      title: 'WATER WELLS',
      products: waterWellsProducts,
      description: 'Give the ultimate Sadaqah Jariyah by building a water well.',
    },
  ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className={`${bgColor ? bgColor : 'bg-[#eaf7ff]'}`}>
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center space-y-5 md:space-y-8">
            {tabButtonsProducts.map(
              (item, index) => (
                  <React.Fragment key={`title_${index}`}>
                    {item.title && (
                      <div className="flex justify-center items-center mx-auto">
                        <p className={`bg-[#14a2dc] text-[#fff] text-[14px] text-center rounded-none p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase`}>
                          {item.title}
                        </p>
                      </div>
                    )}
                    <div ref={contentRef}>
                      {item.title === 'URGENT APPEALS' ? (
                        <>{item.products}</>
                      ) : (
                        <HomePageTabContent showArrows={true} products={item.products} currency={selectedCurrency} />
                      )}
                    </div>
                  </React.Fragment>
                ),
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomePageTabs;
