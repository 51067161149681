import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../../../../store/user";
import scrollToTop from "../../../../../hooks/ui/useScrollToTop";
import { selectProducts } from '../../../../../store/products';
const ProductButton = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    title = 'Donate Now',
    productId = '',
    isScroll = false,
    subTitle
  } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [addProduct] = useHandleAddProduct();
  const [product, setProduct] = useState();
  const products = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */
  const getSelectedProduct = products => {
    let tempSelectedProduct;
    let tempWhereMostNeededProduct;
    products.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          if (Number(innerProduct.status) === 1 && productId === innerProduct.creator) {
            tempSelectedProduct = innerProduct;
          }
          if (Number(innerProduct.status) === 1 && innerProduct.creator === 'MKD-MN-001') {
            tempWhereMostNeededProduct = innerProduct;
          }
        });
      });
    });
    tempSelectedProduct ? setProduct(tempSelectedProduct) : setProduct(tempWhereMostNeededProduct);
  };
  useEffect(() => {
    if (productId) {
      products && Object.keys(products).length && getSelectedProduct(products);
    }
  }, [productId, products]);
  /* -------------------------------------------------------------------------- */

  return <button
    type={'button'}
    disabled={isScroll ? false : !product}
    className={`flex flex-col justify-center items-center ${isScroll ? 'h-[52px] md:h-[54px]' : 'h-[42px] md:h-[44px]'} cursor-pointer justify-center text-center font-['Montserrat'] w-auto font-bold rounded-md px-4 text-[12px] md:text-[14px] transition duration-150 ease-in-out uppercase bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]`}
    onClick={() => {
      isScroll
        ? scrollToTop()
      : addProduct({
          product: product,
          currency: selectedCurrencyStore
        })
    }}>
    {title}
    {subTitle ? <span className="uppercase text-white opacity-75 font-light text-sm">{subTitle}</span> : ''}
  </button>;
};
Builder.registerComponent(ProductButton, {
  name: 'ProductButton',
  inputs: [{
    "name": "title",
    "type": "string"
  },
  {
    "name": "subTitle",
    "type": "string"
  },
  {
    "name": "productId",
    "type": "string"
  },
  {
    name: 'isScroll',
    type: 'boolean',
    defaultValue: false,
    friendlyName: 'Scroll top',
  }]
});
export default ProductButton;