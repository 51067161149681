import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import ProductCard from "../../../../general/productCard";
import IsGTMScriptLoaded from "../../../../../utils/isGTMScriptLoaded";
import getSymbolFromCurrency from 'currency-symbol-map';
import { selectSelectedCurrency } from "../../../../../store/user";
import { selectProducts } from '../../../../../store/products';
const ProductBoxesV2 = ({
  products = []
}) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const _allProducts = useSelector(state => selectProducts(state));
  const handleIsAnyAmount = product => {
    const hasQuantity = product.quantity ? product.quantity > 1 : false;
    return Number(product.aud) <= 2 && !hasQuantity;
  };
  const handleViewItemDataLayer = (tempProduct, currency) => {
    const dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [{
          item_id: tempProduct.creator,
          item_name: tempProduct.name,
          item_brand: tempProduct.category || '',
          item_category: tempProduct.category || '',
          item_description: tempProduct.description || '',
          price: tempProduct[currency.toLowerCase()]
        }]
      }
    };
    window.dataLayer = window.dataLayer || [];
    if (IsGTMScriptLoaded()) {
      window.dataLayer.push(dataLayerTemp);
    }
    const obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category || 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description
    };
    if (window.ttq) {
      window.ttq.instance(localStorage.getItem('TikTokID')).track('ViewContent', obj);
    }
  };
  const ShowCurrencies = (currency, _price) => {
    const tempCurrency = currency?.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let final = '';
    price = price ? price.toLocaleString() : '0';
    switch (tempCurrency) {
      case 'GBP':
      case 'EUR':
        final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        final = `${price}`;
        break;
      case 'IDR':
        final = `Rp${price}`;
        break;
      case 'AED':
        final = `Dh${price}`;
        break;
      default:
        final = `$${price}`;
        break;
    }
    return final;
  };
  const getSelectedProducts = _allProducts => {
    setIsLoaded(true);
    const tempSelectedProducts = [];
    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          if (products.includes(innerProduct.creator)) {
            tempSelectedProducts.push(innerProduct);
          }
        });
      });
    });
    setSelectedProducts(tempSelectedProducts);
  };
  useEffect(() => {
    if (products.length > 0) {
      _allProducts && Object.keys(_allProducts).length && getSelectedProducts(_allProducts);
    }
  }, [_allProducts, products]);
  return <div className="flex w-full px-2 md:px-0 md:container md:mx-auto">
      <div className="flex justify-center flex-wrap w-full">
        {isLoaded ? selectedProducts.length > 0 ? selectedProducts.map((product, index) => <div key={`product-${product.creator}-${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                <ProductCard product={product} alt={''} buttonLabel={'Donate Now'} _lang={'en'} removeDataLayer={false} isVisibleDescription={true} roundedButton={false} squareButton={false} selectedCurrencyStore={selectedCurrencyStore} handleViewItemDataLayer={handleViewItemDataLayer} handleIsAnyAmount={handleIsAnyAmount} ShowCurrencies={ShowCurrencies} />
              </div>) : <p className="text-center w-full">No products selected.</p> : <Skeleton count={4} />}
      </div>
    </div>;
};
Builder.registerComponent(ProductBoxesV2, {
  name: 'ProductBoxesV2',
  inputs: [{
    "name": "products",
    "type": "string"
  }]
});
export default ProductBoxesV2;