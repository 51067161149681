import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout'
// import { selectSelectedCurrency } from '../../store/user'
import { useSelector } from 'react-redux'
import { getProducts, selectProducts } from '../../store/products'
import { useKeenSlider } from 'keen-slider/react'
import KaffarahProduct from '../../images/fidya-kaffarah/kaf-product.jpg';
import FidyaProduct from '../../images/fidya-kaffarah/fid-product.jpg';
import Widget from './widget'
import QuoteOpen from '../../images/general/quote-open.jpg';
import QuoteClose from '../../images/general/quote-close.jpg';
import FidyaKaffUat1 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-1.jpg'
import FidyaKaffUat2 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-2.jpg'
import FidyaKaffUat3 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-3.jpg'
import FidyaKaffUat4 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-4.jpg'
import FidyaKaffUat5 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-5.jpg'
import banner from '../../images/fidya-kaffarah/fidya-kaffarah-hero-banner.jpg'
import bannerMobile from '../../images/fidya-kaffarah/fidya-kaffarah-hero-banner-mobile.jpg'
// import banner from '../../images/fidya-kaffarah/Fidya&Kaffarah-Mercy-Desktop-Hero-Banner.jpg'
// import bannerMobile from '../../images/fidya-kaffarah/Fidya&Kaffarah-Mercy-Mobile-Hero-Banner.jpg'
import { OurPromiseSection } from '../../components/general'

const images = [
    FidyaKaffUat1,
    FidyaKaffUat2,
    FidyaKaffUat3,
    FidyaKaffUat4,
    FidyaKaffUat5,
];


const ShowMoreLessComponent = ({ image, name, desc, btnText }) => {


    const [showMoreButton, setShowMoreButton] = useState(true);

    const toggleContent = () => {

        setShowMoreButton(!showMoreButton);
    };


    return (

        <div className="w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white   xl:min-h-[600px]">
            <div

                className="overflow-hidden img-hover-zoom rounded-md  w-full  tooltip"
                onClick={() => window.scrollTo(0, 500)}
            >
                <img className='w-full' src={image} alt="" />
            </div>
            <div className='text-2xl text-[#14A2DC] font-bold text-center my-6'> {name}</div>
            <div>
                <p className={`font-brandingMedium text-[#78716C] text-[16px] px-6 ${showMoreButton ? "line-clamp-3" : ""}`}>
                    {desc}
                </p>
            </div>
            <div className='flex flex-col items-center justify-center pb-4'>
                {desc?.length > 174 &&


                    <button
                        onClick={toggleContent}
                        className="mt-2 text-blue-500  text-sm  hover:underline cursor-pointer"
                    >
                        {showMoreButton ? "Show More" : "Show Less"}
                    </button>

                }
                <button onClick={() => window.scrollTo(0, 500)}
                    className=' bg-[#F60362] mt-6 rounded-full text-white font-brandingMedium w-[230px] h-[50px]'>
                    {btnText}
                </button>
            </div>


        </div>
    );
};
const FidyaKaffarahUat = () => {
    /* ----------------------------- Start variables ---------------------------- */
    // const [selectedCurrency, setSelectedCurrency] = useState();
    // const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [ramadanProducts, setRamadanProducts] = useState([0, 0]);
    const [isLoaded, setIsLoaded] = useState(false);
    const products = useSelector(state => selectProducts(state));
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        loop: true,
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    },
        [
            slider => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 2000);
                }
                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on('dragStarted', clearNextTimeout);
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]

    )
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];

        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'RM25-00-001') {
                        tempProductsList[0] = { ...inner_item_product };
                        tempProductsList[0].btnText = 'Pay your Kaffarah here';
                    }
                    if (inner_item_product.creator === 'RM25-00-002') {
                        tempProductsList[1] = { ...inner_item_product };
                        tempProductsList[1].btnText = 'Pay your Fidya here';
                    }
                    return inner_item_product;
                });
                return inner_item;
            });
            return item;
        });
        tempProductsList = tempProductsList.filter(n => n)
        setRamadanProducts(tempProductsList);
    };


    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    // useEffect(() => {
    //     selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // }, [selectedCurrencyStore]);
    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])

    /* -------------------------------------------------------------------------- */

    return (
        <Layout className='bg-white ' hideFooter childClasses="pb-0">

            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                <img src={banner} alt="banner" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="banner" className='min-[501px]:hidden w-full' />
            </section>

            <section className='bg-[#E1EFF2]'>
                <section className='max-w-[1440px] mx-auto'>

                    <div className='w-full  lg:mx-auto  xl:px-10 py-3 sm:flex justify-center items-center'>
                        <Widget className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                            defaultProduct={{
                                creator: "RM25-00-001",
                                label: "Kaffarah",
                                value: 759
                            }}
                        />
                    </div>

                </section>
            </section>
            <OurPromiseSection />
            <main className='max-w-[1440px] mx-auto'>
                {/* <section className="max-w-[1440px] md:mx-auto pt-4 sm:pt-8   flex justify-center bg-[url('../public/images/landings/crisis/palestine/pattern.png')]">
                    <div className='flex justify-center'>
                        <div className='flex justify-center items-end sm:items-center basis-full sm:basis-10/12'>
                            <div className='basis-1/2 sm:basis-1/4'>
                                <img src={OurPromises} className="hidden sm:block" alt="our-promise" />
                                <img src={OurPromisesMobile} className="sm:hidden" alt="our-promise" />
                            </div>

                            <div className='basis-1/2 sm:basis-2/3 flex flex-wrap sm:flex-nowrap items-center justify-center sm:justify-start'>

                                <div className='order-2 sm:order-1 sm:basis-2/3 text-[#253B7E] text-[10px] sm:text-[14px] pr-3'>
                                    <div className='border-[#14A2DC] text-center sm:text-left border-b-2 mb-3 pb-1'>
                                        <b className=''>Our promise to you</b>
                                    </div>
                                    <div className='text-[10px] sm:text-[14px] leading-[10px] sm:leading-[16px] pb-5 sm:pb-0'>
                                        Founded by Ali Banat in 2015, Muslims Around The World Project (MATW) is a
                                        global Islamic  Charity working across 24 countries around the world.
                                        Our 100% donation policy ensures that every single donation goes the
                                        extra mile in reaching those most in need.
                                    </div>
                                </div>

                                <div className='order-1 sm:order-2 sm:mb-[40px]'>
                                    <img src={Policy} className='w-[80px] sm:w-[130px]' alt="policy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}



                <section className='xl:px-20 mb-8 sm:mt-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap rounded'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[315px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px] pt-8 w-full text-[30px] sm:text-[50px] md:text-[30px] lg:text-[50px] px-8 text-white text-center font-gotcha'>Fidya and Kaffarah</div>
                            <p className='text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center'>
                                Make sure to give your Fidya and Kaffarah for your missed or broken fasts.
                                <br /><br />
                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <div className=" basis-full max-[380px]:min-h-[209px]  min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[720px]">
                            <iframe className='video pointer-events-none '
                                width={'100%'}
                                height={'100%'}
                                title={'Fidya and Kaffarah'}
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://www.youtube.com/embed/bC7xyV8_r10?controls=0&autoplay=1&loop=1&mute=1&playlist=bC7xyV8_r10`}
                            >
                            </iframe>
                        </div>

                    </div>
                </section>
                <section className='xl:px-20'>
                    <div className='flex justify-center'>
                        <div className='text-left sm:text-center font-bold text-[#093686] text-2xl sm:text-6xl px-4 w-full '>
                            <span className='text-[#F60362]'>Fidya</span> and <span className='text-[#F60362] mr-2'>kaffarah</span>
                            donations are made when fasts during Ramadan are missed or broken. This is used to feed the poor and needy.
                        </div>
                    </div>
                    <div className='px-4  mt-8 sm:mt-16 text-[18px]  leading-5'>
                        <div className='text-[#14A2DC] text-left sm:text-center  flex justify-center  '>
                            <div className='flex flex-col justify-center items-center sm:w-11/12 px-6 py-6 relative shadow-md bg-white min-h-[500px] sm:min-h-[284px] rounded-tr-3xl rounded-bl-3xl border-2 border-[#14A2DC]'>
                                <div className='absolute top-8 left-8'><img src={QuoteOpen} alt="quote" /></div>
                                <div className='absolute right-8 bottom-8'><img src={QuoteClose} alt="quote" /></div>
                                <div className='text-[24px] font-bold'>In the Quran it states: </div>
                                <br />
                                <div className='italic sm:px-24'>
                                    "[Observing fasts] for a fixed number of days, but if any of you is ill or on a journey, the same number
                                    (should be made up) from other days. And as for those who can fast with difficulty, they have
                                    (a choice either to fast or) to feed a Miskin (poor person) (for every day). But whoever does good
                                    of his own accord, it is better for him. And that you fast, it is better for you if
                                    only you know." — <span className='text-[#AAAAAA]'>Quran: 2:184</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 sm:mb-16">
                            {ramadanProducts.map((item, index) => {
                                return (
                                    <div key={`makedonation${index}`} className="basis-full sm:basis-2/4 md:basis-1/3 xl:basis-2/5 p-2">
                                        <ShowMoreLessComponent image={item.image} name={item.name} desc={item.short_description || item.description} btnText={item.btnText} />
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </section>



                {/* /* --------------------------------- Images --------------------------------- */}
                <section className='bg-[#F8F8F8]  py-10'>
                    <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">

                        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {

                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                        {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                        <img src={item} alt={`carousel_images${index}`} className=' bg-top object-cover w-full object-top' />
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="w-full  flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 pb-6">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {/* /* -------------------------------------------------------------------------- */}





                {/* -------------------------------------FAQS--------------------------------- */}
                {/* <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h1 className='text-[#253B7E] text-left  sm:text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                        Zakat donations <span className='text-[#F60362]'> FAQ’s</span>
                    </h1>
                    <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[0].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {zakatFaqs[0].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[1].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {zakatFaqs[1].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[2].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {zakatFaqs[2].answer}
                            </p>
                        </div>
                    </div>
                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {zakatFaqs.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section> */}
            </main>
        </Layout>
    )
}

export default FidyaKaffarahUat;







