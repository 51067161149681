import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import SelectCurrency from './selectCurrency';
import ReactSelect from 'react-select';
import ConvertUSD from '../../utils/convertUSD';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';
import getSymbolFromCurrency from 'currency-symbol-map';
// import withContextMenu from '../../hoc/withContextMenu';
import { selectSelectedCurrency } from '../../store/user';

const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const CrisisWidgetV2 = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    mode = 'one-off',
    floating = false,
    lang = 'en',
    showSuggestedPrices = true,
    productsList = [],
    // defaultName = 'Sadaqah Jariyah',
    defaultName,
    defaultCreator = 'MKD-GEN-MSD-2023-01-121',
  } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productAmount, setProductAmount] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProductsList, setSelectedProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [addProduct] = useHandleAddProduct();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: 106,
  });
  const [currentProduct, setCurrentProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: '',
  });
  const products = useSelector(state => selectProducts(state));
  const _lang = lang.toLowerCase();
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleConvert = (currency, value) => {
    return Math.round(ConvertUSD(currency, value, false));
  };
  const handleSelectedProduct = product => {
    allProducts.map(item => item.creator === product.creator && setSelectedProduct(item));
    setCurrentProduct(product);
  };
  const handleSelectedItem = (currency, index) => {
    let tempSelectedPrice = {};
    tempSelectedPrice.index = index;
    tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
    setSelectedPrice(tempSelectedPrice);
  };
  const handleProductAmount = (currency, value) => {
    setProductAmount(handleConvert(currency, value));
  };

  const suggestedPrices = [
    {
      aud: 100,
      usd: 70,
      cad: 90,
      sgd: 90,
      gbp: 60,
      eur: 60,
      myr: 300,
      aed: 240,
      idr: 1002020,
    },
    {
      aud: 250,
      usd: 160,
      cad: 220,
      sgd: 220,
      gbp: 130,
      eur: 150,
      myr: 760,
      aed: 580,
      idr: 2505050,
    },
    {
      aud: 500,
      usd: 320,
      cad: 430,
      sgd: 440,
      gbp: 260,
      eur: 300,
      myr: 1510,
      aed: 1160,
      idr: 5010090,
    },
  ];
  const [selectedPrice, setSelectedPrice] = useState({
    value: suggestedPrices[1][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
    index: 1,
  });
  const suggestedRecurringModes = [
    { value: 'one-off', label: _lang === 'fr' ? `Don unique` : _lang === 'ar' ? `فردي` : 'Single' },
    { value: 'week', label: _lang === 'fr' ? `Don hebdomadaire` : _lang === 'ar' ? `أسبوعي` : 'Weekly' },
    { value: 'month', label: _lang === 'fr' ? `Don mensuel` : _lang === 'ar' ? `شهري` : 'Monthly' },
  ];
  // Function to get the recurring mode based on the mode value
  const getRecurringMode = (mode) => {
    return suggestedRecurringModes.find((recurringMode) => recurringMode.value === mode) || suggestedRecurringModes[0];
  };

  const [selectedRecurring, setSelectedRecurring] = useState(getRecurringMode(mode));
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });
    addProduct({ product: tempProduct, currency: currentCurrency });
  };
  const ShowCurrencies = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (price && (Number(price) % 1 !== 0)) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = ''
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`
        break;
      case 'MYR':
        _final = `${price}`
        break;
      case 'IDR':
        _final = `Rp${price}`
        break;
      case 'AED':
        _final = `Dh${price}`
        break;
      default:
        _final = `$${price}`
        break;
    }
    return _final
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
      selectedPrice ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index) : handleProductAmount(selectedCurrencyStore, productAmount);
    } else {
      setCurrentCurrency('AUD');
      handleSelectedItem('AUD', selectedPrice.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  const getAllProducts = products => {
    setIsLoaded(true);
    let tempAllProducts = [];
    let tempSelectedProductsList = [];
    let tempAlternativeProductsList = [];
    let tempDefaultProduct;
    let tempAlternativeProduct;

    products.forEach((item) => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          tempAllProducts.push(inner_item_product);
          if (productsList.includes(inner_item_product.creator)) {
            tempSelectedProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === defaultCreator) {
            tempDefaultProduct = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-000') {
            tempAlternativeProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            tempAlternativeProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
            tempAlternativeProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            tempAlternativeProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === 'MKD-SDQ-OAW-2023-02-119') {
            tempAlternativeProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === 'EM-SDQ-EQ1-02-2023') {
            tempAlternativeProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') {
            tempAlternativeProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item.name === 'Make A Donation') {
            if (inner_item_product.creator !== 'MKD-EM-TUR-EQ-01-2023') {
              tempAlternativeProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            tempAlternativeProduct = inner_item.products[0];
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    if (tempDefaultProduct) {
      tempSelectedProductsList.unshift({ value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator });
      tempAlternativeProductsList.unshift({ value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator });
      setSelectedProduct(tempDefaultProduct);
      setCurrentProduct({ value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator })
    } else {
      if (tempAlternativeProduct) {
        setSelectedProduct(tempAlternativeProduct);
        setCurrentProduct({ value: tempAlternativeProduct.id, label: tempAlternativeProduct.name, creator: tempAlternativeProduct.creator })
      }
    }
    let uniqueItems;
    if (tempSelectedProductsList.length) {
      uniqueItems = tempSelectedProductsList.filter((item, index, self) =>
        index === self.findIndex((t) => t.creator === item.creator)
      );
    } else {
      uniqueItems = tempAlternativeProductsList.filter((item, index, self) =>
        index === self.findIndex((t) => t.creator === item.creator)
      );
    }
    setSelectedProductsList(uniqueItems);
    setAllProducts(tempAllProducts);
  };
  useEffect(() => {
    products && Object.keys(products).length && getAllProducts(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, productsList]);
  useEffect(() => {
    console.log("current changed=", currentProduct)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);
  /* -------------------------------------------------------------------------- */
  return floating ? (
    <div dir={_lang === 'ar' ? 'rtl' : 'ltr'} className={`${_lang === 'ar' ? 'font-[AlmariMedium]' : ''} w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}>
      <div className="w-full grid md:grid-cols-2 xl:grid-flow-col auto-cols-max gap-2 ">
        <div className="flex gap-2 notranslate">
          <div className="min-w-[100px]" dir='ltr'>
            <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
          </div>
          <ReactSelect
            options={selectedProductsList}
            className="max-h-[48px] w-full min-w-[260px]"
            defaultValue={currentProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
        </div>

        <div className="flex gap-2 notranslate">
          <ReactSelect
            options={suggestedRecurringModes}
            className="[&>*]:!cursor-pointer w-full"
            value={selectedRecurring}
            isSearchable={false}
            styles={styles}
            onChange={e => {
              setSelectedRecurring(e);
            }}
          />
          {suggestedPrices.map((item, index) => {
            return (
              <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                <div
                  className={`h-12 transition-all duration-200 cursor-pointer flex justify-center items-center min-w-[64px] rounded-md py-2 md:py-4 ${_lang === 'ar' ? 'px-1 text-xs md:text-sm' : 'px-1 md:px-2 md:text-lg'
                    } ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                      ? 'bg-[#F60362] text-white'
                      : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                    }`}
                  onClick={() => {
                    handleSelectedItem(currentCurrency, index);
                    setProductAmount('');
                  }}
                >
                  {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], false)}
                </div>
              </div>
            );
          })}
        </div>
        <input
          value={productAmount}
          className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent min-w-[156px]"
          type="number"
          onChange={e => {
            setProductAmount(e.target.value);
            setSelectedPrice(null);
          }}
          placeholder={_lang === 'ar' ? 'كمية اخرى' : _lang === 'fr' ? 'Autre montant' : 'Other amount'}
        />
        <button
          disabled={!selectedProduct.id}
          className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
          onClick={handleAddNewProduct}
        >
          {_lang === 'fr' ? 'Don rapide' : _lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
        </button>
      </div>
    </div>
  ) : (
    <div
      dir={_lang === 'ar' ? 'rtl' : 'ltr'}
      className={`${_lang === 'ar' ? 'font-[AlmariMedium]' : ''
        } w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}
    >
      <div className="basis-full grid grid-cols-12 gap-2">
        <div className="col-span-12 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          {console.log("before show current=", currentProduct)}
          {currentProduct.label ? <ReactSelect
            options={selectedProductsList}
            className="max-h-[48px] w-full"
            // defaultValue={currentProduct}
            value={currentProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
            : <ReactSelect
              options={selectedProductsList}
              className="max-h-[48px] w-full"
              value={selectedProductsList[0]}
              isSearchable={false}
              styles={styles}
              placeholder="Select Product"
              onChange={e => handleSelectedProduct(e)}
            />}
        </div>
        <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={suggestedRecurringModes}
            className="[&>*]:!cursor-pointer w-full"
            value={selectedRecurring}
            isSearchable={false}
            styles={styles}
            onChange={e => {
              setSelectedRecurring(e);
            }}
          />
        </div>
        <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
        </div>
        <div className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 sm:mb-0">
          {showSuggestedPrices &&
            suggestedPrices.map((item, index) => {
              return (
                <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                  <div
                    className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] border border-[#F60362] ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                      ? 'bg-[#F60362] text-white'
                      : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                      }`}
                    onClick={() => {
                      handleSelectedItem(currentCurrency, index);
                      setProductAmount('');
                    }}
                  >
                    {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0"
          style={styles.shadow}
        >
          <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
          <input
            value={productAmount}
            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
            type="number"
            onChange={e => {
              setProductAmount(e.target.value);
              setSelectedPrice(null);
            }}
            placeholder="Enter amount"
          />
        </div>
        <div className="col-span-12 lg:col-span-4 flex justify-center items-center-">
          <button
            disabled={!selectedProduct.id}
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
              }`}
            onClick={handleAddNewProduct}
          >
            {_lang === 'fr' ? 'Don rapide' : _lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CrisisWidgetV2;
// export default withContextMenu(
//   CrisisWidgetV2,
//   "CrisisWidgetV2",
//   "CrisisWidgetV2",
//   "/src/components/general/crisisWidgetV2.jsx",
//   ["CMS_ADMIN"]
// );
